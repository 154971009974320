<div class="menu_tool container-with-thin-scrollbar" id="menu_tool_id">
<mat-accordion multi="true">
  <!-- ------------------------------------ DISPLAY PANEL -------------------------------------- -->
  <mat-expansion-panel expanded="true" *ngIf="sectionsVisibilityMap['display']">
    <mat-expansion-panel-header>
      <mat-panel-title> Display </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansionBody" >

      <div cdkDropList id="label" (cdkDropListDropped)="dropWidget($event, 'label')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'label'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" mat-stroked-button color="primary" (click)="newWidget('label')">
        <mat-icon aria-hidden="false" aria-label="button icon">text_fields</mat-icon>
        <span class="buttonText">Text</span>
      </button>
      </div>
      <!-- <button class="toolButton" mat-stroked-button color="primary" (click)="newWidget('paragraph')">
        <mat-icon aria-hidden="false" aria-label="button icon">notes</mat-icon>
        <span class="buttonText">Paragraph</span>
      </button> -->

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'link')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'link'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('link')">
        <mat-icon aria-hidden="false" aria-label="button icon">link</mat-icon>
        <span class="buttonText">Link</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'image')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'image'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" mat-stroked-button color="primary" (click)="newWidget('image')">
        <mat-icon aria-hidden="false" aria-label="button icon">image</mat-icon>
        <span class="buttonText">Image</span>
      </button></div>

      <div cdkDropList id="button" (cdkDropListDropped)="dropWidget($event, 'button')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'button'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" mat-stroked-button color="primary" (click)="newWidget('button')">
        <mat-icon aria-hidden="false" aria-label="button icon">crop_7_5</mat-icon>
        <span class="buttonText">Button</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'icon')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'icon'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" mat-stroked-button color="primary" (click)="newWidget('icon')">
        <mat-icon aria-hidden="false" aria-label="button icon">emoji_symbols</mat-icon>
        <span class="buttonText">Icon</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'embed')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'embed'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" mat-stroked-button color="primary" (click)="newWidget('embed')">
        <mat-icon aria-hidden="false" aria-label="button icon">check_box_outline_blank</mat-icon>
        <span class="buttonText">Embed</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'tags')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'tags'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('tags')">
          <mat-icon aria-hidden="false" aria-label="button icon">sell</mat-icon>
          <span class="buttonText">Tags</span>
        </button>
      </div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'separator')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'separator'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('separator')">
          <mat-icon aria-hidden="false" aria-label="button icon">horizontal_rule</mat-icon>
          <span class="buttonText">Separator</span>
        </button>
      </div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'space')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'space'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('space')">
          <mat-icon aria-hidden="false" aria-label="button icon">space_bar</mat-icon>
          <span class="buttonText">Space</span>
        </button>
      </div>
      <!-- <button mat-stroked-button color="primary" (click)="newWidget('card')">
        <mat-icon aria-hidden="false" aria-label="button icon">crop_square</mat-icon>
        <span class="buttonText">Card</span>
      </button> -->
    </div>
  </mat-expansion-panel>

  <!-- ------------------------------------ STANDARD PANEL -------------------------------------- -->

  <mat-expansion-panel expanded="true" *ngIf="sectionsVisibilityMap['standard']">
    <mat-expansion-panel-header>
      <mat-panel-title> Standard </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="expansionBody">

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'input')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'input'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('input')">
        <mat-icon aria-hidden="false" aria-label="button icon">edit_square</mat-icon>
        <span class="buttonText">Text Field</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'textarea')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'textarea'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('textarea')">
        <mat-icon aria-hidden="false" aria-label="button icon">edit_note</mat-icon>
        <span class="buttonText">Text Area</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'richtext')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'richtext'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('richtext')">
        <mat-icon aria-hidden="false" aria-label="button icon">wysiwyg</mat-icon>
        <span class="buttonText">Rich Text</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'numberinput')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'numberinput'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('numberinput')">
        <mat-icon aria-hidden="false" aria-label="button icon">pin</mat-icon>
        <span class="buttonText">Number</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'select')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'select'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('select')">
        <mat-icon aria-hidden="false" aria-label="button icon">arrow_drop_down</mat-icon>
        <span class="buttonText">Dropdown</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'choice')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'choice'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('choice')">
        <mat-icon aria-hidden="false" aria-label="button icon">radio_button_checked</mat-icon>
        <span class="buttonText">Choice</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'checkbox')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'checkbox'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('checkbox')">
        <mat-icon aria-hidden="false" aria-label="button icon">check_box</mat-icon>
        <span class="buttonText">Check Box</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'chips')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'chips'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('chips')">
        <mat-icon aria-hidden="false" aria-label="button icon">edit_attributes</mat-icon>
        <span class="buttonText">Chips</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'autocomplete')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'autocomplete'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('autocomplete')">
        <mat-icon aria-hidden="false" aria-label="button icon">manage_search</mat-icon>
        <span class="buttonText">Autocomplete</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'star')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'star'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('star')">
        <mat-icon aria-hidden="false" aria-label="button icon">star</mat-icon>
        <span class="buttonText">Star</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'slider')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'slider'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('slider')">
        <mat-icon aria-hidden="false" aria-label="button icon">linear_scale</mat-icon>
        <span class="buttonText">Slider</span>
      </button></div>

      <!-- <button class="toolButton" mat-stroked-button color="primary" (click)="newWidget('table')">
        <mat-icon aria-hidden="false" aria-label="button icon">table_view</mat-icon>
        <span class="buttonText">Table</span>
      </button>

      <button class="toolButton" mat-stroked-button color="primary" (click)="newWidget('embed')">
        <mat-icon aria-hidden="false" aria-label="button icon">add_link</mat-icon>
        <span class="buttonText">Embed</span>
      </button> -->
    </div>
  </mat-expansion-panel>


  <!-- ------------------------------------ DATE AND TIME PANEL -------------------------------------- -->

  <mat-expansion-panel *ngIf="sectionsVisibilityMap['dateTime']">
    <mat-expansion-panel-header>
      <mat-panel-title> Date and Time </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansionBody">


      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'date')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'date'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('date')">
        <mat-icon aria-hidden="false" aria-label="button icon">calendar_today</mat-icon>
        <span class="buttonText">Date Input</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'time')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'time'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('time')">
        <mat-icon aria-hidden="false" aria-label="button icon">more_time</mat-icon>
        <span class="buttonText">Time Input</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'datetime')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'datetime'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('datetime')">
        <mat-icon aria-hidden="false" aria-label="button icon">event</mat-icon>
        <span class="buttonText">Date + Time</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'duration')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'duration'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('duration')">
        <mat-icon aria-hidden="false" aria-label="button icon">timer</mat-icon>
        <span class="buttonText">Duration</span>
      </button></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'period')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'period'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('period')">
        <mat-icon aria-hidden="false" aria-label="button icon">date_range</mat-icon>
        <span class="buttonText">Period</span>
      </button></div>

    </div>
  </mat-expansion-panel>

  <!-- ------------------------------------ MEDIA -------------------------------------- -->
  <mat-expansion-panel *ngIf="sectionsVisibilityMap['media']">
    <mat-expansion-panel-header>
      <mat-panel-title> Media </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansionBody">
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'imageinput')">
      <button cdkDrag [cdkDragData]="{newWidgetType: 'imageinput'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('imageinput')">
        <mat-icon aria-hidden="false" aria-label="button icon">add_photo_alternate</mat-icon>
        <span class="buttonText">Image Input</span>
      </button></div>
    </div>
  </mat-expansion-panel>

  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Advanced </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansionBody">
      <p>list of all the advcanced tools</p>
    </div>
  </mat-expansion-panel> -->

  <mat-expansion-panel *ngIf="sectionsVisibilityMap['charts']">
    <mat-expansion-panel-header>
      <mat-panel-title> Charts </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansionBody">
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'chart|pie')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'chart|pie'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('chart|pie')">
          <mat-icon aria-hidden="false" aria-label="button icon">pie_chart</mat-icon>
          <span class="buttonText">Pie Chart</span>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'chart|bar')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'chart|bar'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('chart|bar')">
          <mat-icon aria-hidden="false" aria-label="button icon">bar_chart</mat-icon>
          <span class="buttonText">Bar Chart</span>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'chart|line')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'chart|line'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('chart|line')">
          <mat-icon aria-hidden="false" aria-label="button icon">show_chart</mat-icon>
          <span class="buttonText">Line Chart</span>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'chart|gauge')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'chart|gauge'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('chart|gauge')">
          <mat-icon aria-hidden="false" aria-label="button icon">data_exploration</mat-icon>
          <span class="buttonText">Gauge Chart</span>
        </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'chart|scorecard')">
        <button cdkDrag [cdkDragData]="{newWidgetType: 'chart|scorecard'}" (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" (cdkDragEnded)="dragEnd($event)" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('chart|scorecard')">
          <mat-icon aria-hidden="false" aria-label="button icon">score</mat-icon>
          <span class="buttonText">Score card</span>
        </button>
      </div>
    </div>
  </mat-expansion-panel>


    <!-- ------------------------------------Connections -------------------------------------- -->
    <!-- <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Connections </mat-panel-title>
      </mat-expansion-panel-header>

    </mat-expansion-panel> -->

  


  <mat-expansion-panel *ngIf="sectionsVisibilityMap['panels']">
    <mat-expansion-panel-header>
      <mat-panel-title> Panels </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansionBody panelExpansion" fxLayout="column" fxLayoutAlign="center strech">
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'searchPanel')">
        <div cdkDrag [cdkDragData]="{newWidgetType: 'searchPanel'}"
          (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" class="toolButton panelButton" mat-stroked-button color="primary" (click)="newWidget('searchPanel')"
          fxLayout="column" fxLayoutAlign="center center">
          <button style="cursor:pointer;" 
            mat-button color="primary" 
            class="panelButtonHeading" 
            fxLayout fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap=".5rem"
          >
            <mat-icon aria-hidden="false" aria-label="button icon">search</mat-icon>
            <span>Search Panel</span>
          </button>
          <div class="panelText" fxLayout fxLayoutAlign="center center">
            Search panel lets you search on your app data
          </div>
        </div>
      </div>


      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'listPanel')">
      <div cdkDrag [cdkDragData]="{newWidgetType: 'listPanel'}"
        (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" class="toolButton panelButton" mat-stroked-button color="primary" (click)="newWidget('listPanel')"
        fxLayout="column" fxLayoutAlign="center center">
        <button style="cursor:pointer;" 
          mat-button color="primary" class="panelButtonHeading" 
          fxLayout fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap=".5rem"
        >
          <mat-icon aria-hidden="false" aria-label="button icon">format_list_bulleted</mat-icon>
          <span>List Panel</span>
        </button>
        <div class="panelText" fxLayout fxLayoutAlign="center center">
          List panel lets you display any app data in a tabular form
        </div>
      </div></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'formPanel')">
      <div cdkDrag [cdkDragData]="{newWidgetType: 'formPanel'}"
        (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" class="toolButton panelButton" mat-stroked-button color="primary" (click)="newWidget('formPanel')"
        fxLayout="column" fxLayoutAlign="center center">
        <button style="cursor:pointer;" 
          mat-button color="primary" 
          class="panelButtonHeading" 
          fxLayout fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap=".5rem"
        >
          <mat-icon aria-hidden="false" aria-label="button icon">assignment</mat-icon>
          <span>Form Panel</span>
        </button>
        <div class="panelText" fxLayout fxLayoutAlign="center center">
          Form panel lets you edit or update your app data
        </div>
      </div></div>

      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'detailsPanel')">
      <div cdkDrag [cdkDragData]="{newWidgetType: 'detailsPanel'}"
        (cdkDragStarted)="dragStarted()" (cdkDragMoved)="dragMove($event)" class="toolButton panelButton" mat-stroked-button color="primary" (click)="newWidget('detailsPanel')"
         fxLayout="column" fxLayoutAlign="center center">
        <button style="cursor:pointer;" 
          mat-button color="primary" 
          class="panelButtonHeading" 
          fxLayout fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap=".5rem"
        >
          <mat-icon aria-hidden="false" aria-label="button icon">article</mat-icon>
          <span>Details Panel</span>
        </button>
        <div class="panelText" fxLayout fxLayoutAlign="center center">
          Details panel shows all the details of a record from your app
        </div>
      </div></div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="sectionsVisibilityMap['connections']" (opened)="connectionPanelOpenState = true" (closed)="connectionPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title> 
        <img src="{{environment.LOGO_BASE_URL}}connection/appiworks-connection-logo.png"
                          style="height: 20px; width: 20px;" />
        Connection</mat-panel-title>
    </mat-expansion-panel-header>
    <div style="padding-left: 24px;"> Workspace Connections</div>
    <div class="expansionBody" style="display: contents" *ngIf="connectionPanelOpenState">
      <connection-panel-list></connection-panel-list>
    </div>
    <div style="padding-left: 24px"> End User Connections</div>
    <div fxlayout fxLayout.lt-lg="column" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-bottom: 10px;">
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'connection')">
      <button cdkDrag (cdkDragStarted)="dragStarted()" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('connection')">
        <mat-icon aria-hidden="false" aria-label="button icon">cable</mat-icon>
        <span class="buttonText">Connection</span>
      </button>
      </div>
      <div cdkDropList (cdkDropListDropped)="dropWidget($event, 'connection-list')" style="width: fit-content">
        <button  style="width: 100% !important" cdkDrag (cdkDragStarted)="dragStarted()" class="toolButton" mat-stroked-button color="primary" (click)="newWidget('connection-list')">
          <mat-icon aria-hidden="false" aria-label="button icon">hub</mat-icon>
          <span class="buttonText">Connection Listing</span>
        </button>
        </div>
    </div>
  </mat-expansion-panel>

  <!-- ------------------------------------ STARCH -------------------------------------- -->
  <mat-expansion-panel *ngIf="sectionsVisibilityMap['starch']" (opened)="starchPanelOpenState = true" (closed)="starchPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>

        <img src="{{environment.LOGO_BASE_URL}}starch/appiworks-starch-logo.png"
                          style="height: 20px; width: 20px;" />
        <div  class="col-md-9">
          Starch (System Databases)
        </div>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <div class="expansionBody" style="display: contents" *ngIf="starchPanelOpenState">
      <starch-base></starch-base>
    </div>
  </mat-expansion-panel>

</mat-accordion>

</div>
